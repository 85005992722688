import React from "react"
import { navigate } from "gatsby"
import { Page, Badge, Button } from "@shopify/polaris"
import formatLastSavedDate from "../helpers/formatLastSavedDate"
import {updateRule} from '../helpers/updateRule';
import { updateCustomization } from '../helpers/updateCustomization';
import update from 'immutability-helper';
import styled from 'styled-components';
const PageHeaderWrapper = styled.div`
  .Polaris-Page-Header--mediumTitle {
    width: 622.66px;
  }
  .Polaris-Page-Header--longTitle {
    width: 622.66px;
  }
  .Polaris-Page-Header__Row {
    width: 622.66px;
  }
`
const PageHeader = ({
  children,
  title,
  location,
  link,
  existingOffer,
  token,
  shop,
  firebase,
  state,
  setState,
  hasUnsavedChanges,
  handleFormSubmit,
  isEditing,
  isSubmitting,
  windowDimensions,
}) => {

  const toggleEnabled = (rule) => {
    if("custom content" === rule.type || "custom field" === rule.type || "address blocker" === rule.type){
      updateCustomization({
        id: rule.id,
        enabled: !rule.enabled
      }, shop, firebase);
    }else{
    updateRule({
        id: rule.id,
        enabled: !rule.enabled
      },
      token, shop, firebase);
    }
  };

  const clickHandler = (e) => {
    e.stopPropagation();
    toggleEnabled(state);

    setState(update(state, { enabled: { $set: !state.enabled }}))
  }
  return (
    isEditing ? (
      windowDimensions?.width < 1290 ?
      <Page
      title={title}
      subtitle={formatLastSavedDate(existingOffer?.updatedAt)}
      titleMetadata={hasUnsavedChanges ? <Badge>Unsaved Changes</Badge> : (state.enabled ? <Badge tone="success">Live</Badge> : <Badge tone="info">Draft</Badge>)}
      primaryAction={state.enabled ? <Button onClick={(e) => clickHandler(e)}>Turn off</Button> : <Button variant="primary" onClick={(e) => clickHandler(e)}>Turn on</Button>}
      backAction={{
        onAction: () => {
          navigate(link, {
            state: location.state,
            replace: true,
          })
        }}} 
    >
      {children}
    </Page>
    :
    <PageHeaderWrapper>
    <Page
      title={title}
      subtitle={formatLastSavedDate(existingOffer?.updatedAt)}
      titleMetadata={hasUnsavedChanges ? <Badge>Unsaved Changes</Badge> : (state.enabled ? <Badge tone="success">Live</Badge> : <Badge tone="info">Draft</Badge>)}
      primaryAction={state.enabled ? <Button onClick={(e) => clickHandler(e)}>Turn off</Button> : <Button variant="primary" onClick={(e) => clickHandler(e)}>Turn on</Button>}
      backAction={{
        onAction: () => {
          navigate(link, {
            state: location.state,
            replace: true,
          })
        }}} 
    >
      {children}
    </Page>
    </PageHeaderWrapper>
    )
    :
    (
      <Page
      title = "Create Offer"
      >
      {children}
    </Page>
    )
  )
}
export default PageHeader
