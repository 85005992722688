import React, {
    useState,
    useCallback,
    useEffect,
  } from 'react'
  import {
    LegacyCard,
    Tabs,
  } from "@shopify/polaris"
  import CartDrawerDesktopPreview from './cartDrawerDesktopPreview'
  import CardDivider from '../../../components/cardDivider';

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
  function CartDrawerPreview(props) {
    const [toggleValue, setToggleValue] = useState("Desktop")
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [selected, setSelected] = useState(0);

    const handleTabChange = useCallback(
      (selectedTabIndex) => setSelected(selectedTabIndex),
      [],
    );

    const tabs = [
      {
        id: 'desktop-cart-preview-fitted-2',
        content: 'Desktop cart preview',
        panelID: 'desktop-cart-preview-2',
      },
      {
        id: 'mobile-cart-preview-2',
        content: 'Mobile cart preview',
        panelID: 'mobile-cart-preview-2',
      },
    ];
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
      <div style={{maxHeight: windowDimensions.height ? windowDimensions.height-50 : '100%',}}>
        <LegacyCard>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
        <CardDivider/>
        <LegacyCard.Section>
          <div>
            <div style={{padding: '30px 15px', background: 'black',}}>
              {0 === selected ?
                <CartDrawerDesktopPreview {...props}/>
                :
                <CartDrawerDesktopPreview {...props}/>
              }
            </div>
          </div>
        </LegacyCard.Section>
        </Tabs>
        </LegacyCard>
      </div>
    );
  }
  
  export default CartDrawerPreview;